import ExclamationIcon from "./utils/iconComponents/ExclamationIcon";

const PostModalErrorAddCatalog = () => {
  return (
    <div className='w-full flex justify-center'>
      <div className='w-[80%] shadow rounded-md p-4'>
        <div className='pt-2 pr-0 pb-1 pl-0 w-full flex justify-center'>
          <span className='flex text-primary-700 w-[40px]'>
            <ExclamationIcon />
          </span>
        </div>
        <div className='pt-2 pr-0 pb-4 pl-0'>
          <p className='flex text-justify'>
            El artículo seleccionado no puede ser asociado en MercadoLibre
            debido a restricciones de la plataforma. Para continuar, puede optar
            por eliminar la publicación actual y crear una nueva con el GTIN
            correcto. ¿Desea proceder con esta acción?
          </p>
        </div>
        <div className='grid grid-cols-2 gap-2'>
          <div>
            <button
              type='button'
              className={`text-black bg-white border border-gray-200 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150' w-full h-[44px] justify-center`}
              onClick={() => null}
            >
              Cancelar
            </button>
          </div>
          <div>
            <button
              className={`text-white bg-primary-700 border border-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md transition ease-in-out duration-150' w-full h-[44px] justify-center cursor-pointer`}
              type='button'
              onClick={() => null}
            >
              Eliminar y crear nueva
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostModalErrorAddCatalog;
